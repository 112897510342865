import React from 'react';

import './css/header.css';
import logo from './media/social-security-scotland-logo.svg';

class Header extends React.Component {

    render() {
        return (
            <div className="header">
                <header className="header-bar wrapper" role="banner">
                    <div className="site-brand__link logo-size">
                        <div id="mygov-logo" className="site-brand__logo">
                            <img src={logo} alt="Social Security Scotland" />
                        </div>
                    </div>
                </header>                
            </div>
        );
    }
}

export default Header;