import React from 'react';

import './css/footer.css';
import logo from './media/footer-logo.svg';

class Footer extends React.Component {

    render() {
        return (
            <div className="footer">
                <footer id="site-footer" className="site-footer">
                    <div className="wrapper">
                        <ul className="site-items">
                            <li className="site-items__item">
                                <a className="site-items__link" href="https://www.mygov.scot/accessibility/" target="_self" title="Accessibility" data-gtm="link-footer">Accessibility</a>
                            </li>
                            <li className="site-items__item">
                                <a className="site-items__link" href="https://www.mygov.scot/contact-social-security-scotland/" target="_self" title="Contact us" data-gtm="link-footer">Contact us</a>
                            </li>            
                            <li className="site-items__item">
                                <a className="site-items__link" href="https://www.mygov.scot/social-security-data/" target="_self" title="Privacy" data-gtm="link-footer">Privacy</a>
                            </li>
                            <li className="site-items__item">
                                <a className="site-items__link" href="https://www.socialsecurity.gov.scot/" target="_self" title="Social Security Corporate Site" data-gtm="link-footer">Social Security Corporate Site</a>
                            </li>            
                        </ul>
                        <div className="grid">
                            <div className="grid__item medium--full site-footer__summary">
                                <p>mygov.scot is the place for people in Scotland to access public services that are easy to find and simple to use.</p>
                            </div>
                            {/* COMMENTED due to the similarity of the gov.scot logo featuring a blue cross on a white flag to a Russian naval flag
                                    (see Michael Reynolds' email, Friday 11/03/2022)

                            <div className="grid__item medium--six-twelfths footer-logo">
                                <a data-gtm="logo-govscot" id="govscot-logo" className="site-footer__gov-logo" title="The Scottish Government" href="https://www.mygov.scot/">
                                    <img src={logo} alt="The Scottish Government" />
                                </a>
                            </div>*/}
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;