import React from 'react';

/**
 * Component representing referrals social security landing page
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Step1(props) {

    if (props.currentStep !== 1) {
        return null
    }

    return (
        <div className="step step-1">
            <h1>How to report suspected benefit fraud in Scotland</h1>
            <p>We've moved our referral platform to a new provider. If you want to report suspected benefit fraud in Scotland, visit <a href={"https://www.mygov.scot/benefit-fraud"}>MyGov.scot/benefit-fraud</a>.</p>
        </div>
    )
}

export default Step1;