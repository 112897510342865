import React from 'react';

import './css/base.css';

import Header from "./header";
import MasterForm from "./form/masterform";
import Footer from "./footer";

class Base extends React.Component {

    render() {

        return(
            <div id="base">
                <a id="skip-link" className="visually-hidden skip-link" href="#masterForm">Skip to content</a>
                <Header />
                <MasterForm />
                <Footer />
            </div>
        );
    }
}

export default Base;