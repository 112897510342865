import React from "react";

import './../css/masterform.css';

import Step1 from "./step1";

/**
 * Master Component
 */
class MasterForm extends React.Component {

    constructor(props) {
        super(props);

        /**
         * NOTE variables declared inside this.state to be passed to the Controlled Components (e.g. Step1, Step2, etc)
         *      , so that MasterForm has all the information gathered from all the form steps
         */
        this.state = {
            currentStep: 1
        };
    }

    /**
     * Component render()
     */
    render() {
        return (
            <React.Fragment>
                <div id="masterForm" className="wrapper">
                    <Step1 currentStep={this.state.currentStep} />
                </div>
            </React.Fragment>
        )
    }
}

export default MasterForm;